import './App.css';
import React, { useState } from 'react';
import AutoBot from './components/AutoBot';
import { Layout, Menu, Avatar, Dropdown } from 'antd';
import { UserOutlined, LogoutOutlined, RobotOutlined } from '@ant-design/icons';

const { Sider, Content } = Layout;

function App() {
  const [selectedMenu, setSelectedMenu] = useState('autobot');
  const userName = 'Admin';

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<UserOutlined />}>
        Profile
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const handleMenuClick = (menuKey) => {
    setSelectedMenu(menuKey);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case 'autobot':
        return <AutoBot />;
      default:
        return null;
    }
  };

  return (
    <div className="App">
      <Layout style={{ minHeight: '10px' }}> {/* Reduced height */}
        <Sider width={200} style={{ background: '#001529' }}>
          <div className="logo" style={{ color: 'white', padding: '16px', textAlign: 'center' }}>
            First Outcomes
          </div>
          <Menu
            mode="inline"
            defaultSelectedKeys={['autobot']} 
            style={{ height: '100%', borderRight: 0 }}
            theme="dark"
            onClick={(e) => handleMenuClick(e.key)}
          >
            <Menu.Item key="autobot" icon={<RobotOutlined />}>
              AutoBot
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 80,
              backgroundColor: '#fff',
            }}
          >
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default App;
