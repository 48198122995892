// MainComponent.jsx or wherever your Tabs are defined
import React from 'react';
import { Tabs } from 'antd';
import AutoBotAI from './AutoBotAI';
import SearchCallLogs from './SearchCallLogs';

const { TabPane } = Tabs;

const MainComponent = (props) => {
    return (
        <Tabs defaultActiveKey="1">
            <TabPane tab="Auto Bot AI" key="1">
                <AutoBotAI {...props} />
            </TabPane>
            <TabPane tab="Search Call Logs" key="2">
                <SearchCallLogs {...props} />
            </TabPane>
        </Tabs>
    );
};

export default MainComponent;
