import axios from "axios";


export async function GetBotList() {
    let tempList = [];
    let response = await axios.get(process.env.REACT_APP_BASE_API_URL + '/autoBot/getBotList');
    if(response.status === 200){
        tempList = response.data;
    }
    return tempList;
}


export async function BotMakeCall(from, to, workFlowId) {
    let tempList = [];
    let response = await axios.post(process.env.REACT_APP_BASE_API_URL + '/autoBot/makeCall', {
        From: from,
        To: to,
        WorkFlowId: Array.isArray(workFlowId) ? workFlowId : [workFlowId]
    });

    if(response.status === 200){
        tempList = response.data;
    }
    return tempList;
}

export async function GetDashboardData(from, to) {
    let tempList = [];
    let response = await axios.post(process.env.REACT_APP_BASE_API_URL + '/autoBot/getDashboardData', {
        From: from,
        To: to,
    });

    if(response.status === 200){
        tempList = response.data;
    }
    return tempList;
}

export async function GetCallStatus(internalConversationId) {
    let status="Pending";
    
    let response = await axios.post(process.env.REACT_APP_BASE_API_URL + '/autoBot/getCallStatus', {
        ConversationId: internalConversationId
    });

    if(response.status === 200){
        status = response.data.status;
    }
    return status;
}

export async function GetConversationMessages(internalConversationId) {
    let tempList = [];
    let response = await axios.post(process.env.REACT_APP_BASE_API_URL + '/autoBot/getConversationMessages', {
        ConversationId: internalConversationId
    });

    if(response.status === 200){
        tempList = response.data;
        console.log(response.data);
    }
    return tempList;
}
